import React from "react"

import classNames from "classnames"

import DefaultLayout from "../layout/default"
import CtaForm from "../pagesSections/index/CtaForm"
import {useLocalizationContext} from "../localization/useLocalizationContext";

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"
import styles from "./styles/_V2/docs.module.scss"
import {externalLinks} from "../pages-data/_V2/common/links";

export default function PagePrivacyPolicy() {

    const localizationContext = useLocalizationContext()

    const metaUrl = {
        'ru-RU': `${externalLinks.quickrestoRU.href}privacy_policy/`,
        'ru-KZ': `${externalLinks.quickrestoKZ.href}privacy_policy/`,
        'kz-KZ': `${externalLinks.quickrestoKZ.href}privacy_policy/`,
        'ru-BY': `${externalLinks.quickrestoBY.href}privacy_policy/`,
    }
    const currentCanonicalLink = metaUrl[localizationContext.locale]

    const metaTags = [
        // {
        //   name: 'keywords',
        //   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
        // },
        {
            name: "description",
            content:
                "Положения политики обработки персональных данных в соответствии с ФЗ-152 «О персональных данных» определяют порядок обработки персональных данных и меры по обеспечению безопасности персональных данных предпринимаемые ООО «Квик Ресто».",
        },
        {
            property: "og:url",
            content: currentCanonicalLink,
        },
        {
            property: "og:title",
            content:
                "Политика в отношении обработки персональных данных | Quick Resto",
        },
        {
            property: "og:description",
            content:
                "Положения политики обработки персональных данных в соответствии с ФЗ-152 «О персональных данных» определяют порядок обработки персональных данных и меры по обеспечению безопасности персональных данных предпринимаемые ООО «Квик Ресто».",
        },
        {
            property: "og:type",
            content: "website",
        },
    ]

    if (localizationContext.locale === 'ru-BY') {
        return (
            <DefaultLayout
                title="Политика в отношении обработки персональных данных | Quick Resto"
                metaTags={metaTags}
                mainWrapperClassName={classNames(
                    pageStyles.pageWrapper,
                    pageStyles.pageGray,
                    styles.docs
                )}
                linkCanonical="https://quickresto.by/privacy_policy/"
                isHeaderFixed={true}
                footerClassName={pageStyles.pageSection}
            >
                <section
                    className={classNames(styles.docs__container, pageStyles.pageSection)}
                >
                    <div className={styles.docs__content}>
                        <h1>
                            Политика конфиденциальности
                        </h1>

                        <div>
                            <p></p>
                            <p>Нашей задачей является безопасность и конфиденциальность при обработке данных
                                пользователей, поэтому мы с уважением относимся к соблюдению прав субъектов персональных
                                данных.
                                Утверждение настоящей Политики конфиденциальности (далее – Политика) является одной из
                                принимаемых мер, предусмотренных статьей 17 Закона Республики Беларусь от 7 мая 2021г.
                                №99‑З «О защите персональных данных» (далее – Закон).
                                Настоящая Политика регулирует порядок обработки данных, которые предоставляются
                                пользователями веб-сайта quickresto.by или лицами, которые используют для направления
                                сообщений в наш адрес через иные каналы связи (мессенджеры, e-mail, заполнение формы
                                обратной связи, электронные сообщения в адрес службы поддержки и т.п.) (далее –
                                пользователи).
                                Политика разъясняет пользователям, как и для каких целей их персональные данные
                                собираются, используются или иным образом обрабатываются, а также отражает имеющиеся в
                                связи с этим у пользователей права и механизм их реализации.
                                Настоящая Политика не применяется к обработке персональных данных в процессе трудовой
                                деятельности и при осуществлении административных процедур (в отношении работников и
                                бывших работников).
                                Для реализации прав, установленных настоящей Политикой, Вы можете использовать следующие
                                контактные данные Организации:
                                Почтовый адрес: ИП Дятлов Кирилл Валентинович, 223040, Минский район, аг. Лесной,
                                41-120.
                                e-mail: info@quickresto.by</p>

                            <p>Политика обязательна для ознакомления и исполнения всеми лицами, допущенными к обработке
                                персональных данных в Компании, и лицами, участвующими в организации процессов обработки
                                и обеспечения защиты персональных данных в Компании.
                                Настоящая Политика вступает в силу с момента ее утверждения.
                                Обеспечение неограниченного доступа к Политике реализуется путем ее публикации в
                                открытом доступе на сайте Компании.</p>
                        </div>
                        <div>
                            <p>Принципы обработки персональных данных</p>

                            <p>Компания предпринимает действия по защите частной жизни субъектов персональных данных, а
                                также их прав на конфиденциальность, вне зависимости от способов сбора персональных
                                данных, с учетом следующих принципов их обработки:
                                1. принцип законности и прозрачности. Персональные данные будут обрабатываться законно,
                                справедливо и прозрачно на основании настоящей Политики и норм законодательства
                                Республики Беларусь. В случае, если субъект не согласен с обработкой персональных
                                данных, Компания уведомляет, что сервисы, реализация которых непосредственно связана с
                                обработкой персональных данных, могут быть недоступны, а пользование сайтом ограничено;
                                2. принцип ограничения целей. Персональные данные обрабатываются Компанией только в явно
                                выраженных и законных целях и не должны в дальнейшем обрабатываться способом,
                                несовместимым с этими целями;
                                3. принцип минимизации данных. Перечень персональных данных должен ограничиваться только
                                той информацией, которая необходима в отношении целей, для достижения которых она
                                обрабатывается;
                                4. принцип точности и достоверности данных. Персональные данные должны быть точными и
                                достоверными. Субъектом персональных данных должны быть предприняты все разумные меры,
                                чтобы неполные, устаревшие или неточные персональные данные, в зависимости от целей их
                                обработки, были удалены или исправлены без задержки; 9.5. принцип ограничения срока
                                хранения данных. Персональные данные должны храниться в форме, позволяющей
                                идентифицировать субъекта персональных данных, но не дольше, чем этого требуют цели
                                обработки персональных данных; 9.6. принцип конфиденциальности и безопасности данных.
                                Персональные данные должны обрабатываться Компанией таким образом, который обеспечит
                                надлежащую безопасность персональных данных, включая защиту от несанкционированной или
                                незаконной обработки, а также от случайной утери, уничтожения или повреждения с
                                использованием соответствующих правовых, организационных и технических мер.</p>
                        </div>
                        <div>
                            <p>Цели и праовые основания обработки персональных данных. Категории субъектов и объем
                                обрабатываемых персональных данных</p>

                            <p>Компания, выступая в качестве оператора, обрабатывает персональные данные субъектов в
                                целях и на основаниях, указанных ниже.
                                Пользователи сайта, зарегистрировавшие личный кабинет.
                                Обрабатываемые данные: Фамилия, имя, отчество; Адрес электронной почты; Номер телефона.
                                Цель обработки: Предоставление пользователю услуг (работ), оказываемых Компанией;
                                Направление уведомлений, касающихся услуг (работ), оказываемых Компанией; При
                                поступлении запроса от пользователя – подготовка и направление ответов; Идентификация
                                субъекта персональных данных; Выполнение обязанностей (полномочий), предусмотренных
                                законодательными актами Республики Беларусь; Обеспечение исполнения норм действующего
                                законодательства, исполнения судебных актов, актов других органов или должностных лиц,
                                подлежащих исполнению в соответствии с законодательством; Осуществление информационных и
                                рекламных рассылок, направление коммерческих предложений субъектам персональных данных.
                                Правовое основание: Договор, заключенный с субъектом персональных данных и (или)
                                Согласие</p>

                            <p>Пользователи сайта, которые заполняют формы на сайте (в частности, заявки, формы подписки
                                на рассылку, формы обратной связи, регистрации на мероприятия и иные)
                                Обрабатываемые данные: Фамилия, имя, отчество; Адрес электронной почты; Номер телефона.
                                Цель обработки: Выполнение действий, требуемых в соответствии с запросом субъекта
                                персональных данных (ответ на запрос, консультирование по вопросу субъекта персональных
                                данных и иное).
                                Правовое основание: абз. 20 ст. 6 и абз. 16 п. 2 ст. 8 Закона, п. 1 ст. 3 Закона
                                Республики Беларусь от 18 июля 2011 г. № 300-З «Об обращениях граждан и юридических
                                лиц»; Договор, заключенный с субъектом персональных данных; и (или) Согласие.</p>

                            <p>Компания обеспечивает соответствие содержания и объёма обрабатываемых персональных данных
                                заявленным целям обработки и при необходимости, принимает меры по устранению их
                                избыточности по отношению к заявленным целям обработки. При изменении любой из указанных
                                выше целей обработки, на которую Компания брала согласие субъекта персональных данных,
                                при отсутствии иных оснований для такой обработки, Компания обязана запросить у субъекта
                                персональных данных повторное согласие в соответствии с измененной целью.
                                Компания может обрабатывать персональные данные субъектов в рамках следующих действий
                                (или совокупность действий): сбор, систематизация, хранение, изменение, использование,
                                обезличивание, предоставление, удаление персональных данных.
                                Способы обработки Компанией персональных данных: с использованием средств автоматизации;
                                без их использования; смешанным способом.
                                Компания обрабатывает специальные персональные данные только при условии согласия
                                субъекта персональных данных либо без согласия в случаях, предусмотренных
                                законодательством Республики Беларусь.
                                Компания в случае необходимости для достижения целей обработки данных вправе передавать
                                персональные данные третьим лицам с соблюдением требований законодательства Республики
                                Беларусь.
                                Сроки обработки Компанией персональных данных определяются с учетом: установленных целей
                                обработки персональных данных; условий договоров, заключенных с субъектами персональных
                                данных; сроков действия согласий субъектов персональных данных на обработку их
                                персональных данных; сроков, определенных Регламентом и законодательством Республики
                                Беларусь.
                                Компания прекращает обработку персональных данных в следующих случаях: при наступлении
                                условий прекращения обработки персональных данных или по истечении установленных сроков;
                                по достижении целей их обработки либо в случае утраты необходимости в достижении этих
                                целей; по требованию субъекта персональных данных, в случаях, предусмотренных
                                законодательством Республики Беларусь о защите персональных данных; в случае выявления
                                неправомерной обработки персональных данных, если обеспечить правомерность обработки
                                персональных данных невозможно; в случае ликвидации Компании.</p>
                        </div>
                        <div>

                            <p>Иная информация о субъектах персональных данных, обрабатываемая компанией</p>

                            <p>Компания также вправе обрабатывать иную информацию о субъектах персональных данных,
                                которая включает в себя: данные, автоматически получаемые сервером при доступе к сайту с
                                использованием закладок (файлы cookie); данные, автоматически получаемые сервером при
                                доступе к сайту и последующих действиях пользователя на сайте (включая, но не
                                ограничиваясь: IP-адрес хоста, вид операционной системы пользователя сайта, тип
                                браузера, страницы сайта, посещаемые пользователем).</p>
                        </div>
                        <div>

                            <p>Порядок и условия обработки персональных данных</p>

                            <p>Основанием обработки персональных данных является согласие субъекта персональных данных,
                                за исключением случаев, установленных законодательством Республики Беларусь, когда
                                обработка персональных данных осуществляется без получения такого согласия.
                                Согласие субъекта персональных данных представляет собой свободное, однозначное,
                                информированное выражение его воли, посредством которого он разрешает обработку своих
                                персональных данных. Отказ в даче согласия на обработку персональных данных дает право
                                Компании отказать субъекту персональных данных в предоставлении услуг (работ) Компании,
                                осуществление которых непосредственно связано с обработкой персональных данных.
                                Хранение персональных данных осуществляется в форме, позволяющей определить субъекта
                                персональных данных, в течение срока не дольше, чем этого требуют цели обработки
                                персональных данных, кроме случаев, когда срок хранения персональных данных установлен
                                законодательством Республики Беларусь, договором, заключенным (заключаемым) с субъектом
                                персональных данных, в целях совершения действий, установленных этим договором.
                                Условием прекращения обработки персональных данных может являться достижение целей
                                обработки персональных данных, истечение срока обработки персональных данных, отзыв
                                согласия субъекта персональных данных на обработку его персональных данных, а также
                                выявление неправомерной обработки персональных данных.
                                Компания, выступая в качестве уполномоченного лица, осуществляет обработку персональных
                                данных субъектов, являющихся клиентами третьих лиц, действует по поручению на обработку
                                персональных данных в соответствии с законодательством Республики Беларусь.
                                ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ И МЕХАНИЗМ РЕАЛИЗАЦИИ ЭТИХ ПРАВ реализуются согласно
                                законодательства Республики Беларусь.
                            </p>
                        </div>
                        <div>
                            <p>Меры, применяемые компанией для защиты персональных данных субъектов</p>

                            <p>Компания принимает, а также постоянно совершенствует необходимые правовые,
                                организационные и технические меры по обеспечению защиты персональных данных от
                                несанкционированного или случайного доступа к ним, изменения, блокирования, копирования,
                                распространения, предоставления, удаления персональных данных, а также от иных
                                неправомерных действий в отношении персональных данных.
                                Правовые меры, принимаемые Компанией: Компании разработаны и введены в действие
                                документы, устанавливающие порядок обработки и обеспечения защиты персональных данных,
                                которые обеспечивают соответствие требованиям Закона и иных актов законодательства
                                Республики Беларусь, регулирующих отношения в сфере персональных данных.
                                Организационные меры, принимаемые Компанией, включают в себя: назначение Компанией лица
                                и (или) структурного подразделения, ответственного за осуществление внутреннего контроля
                                за обработкой персональных данных;
                                ознакомление работников Компании с требованиями законодательства Республики Беларусь и
                                нормативными документами Компании в области работы с персональными данными; определение
                                Компанией перечня лиц, доступ которых к персональным данным, обрабатываемым в
                                информационной системе, необходим для выполнения ими трудовых обязанностей; издание
                                внутренних документов, определяющих политику Компании в отношении обработки персональных
                                данных, локальных правовых актов по вопросам обработки персональных данных, а также
                                локальных правовых актов, устанавливающих процедуры, направленные на предотвращение и
                                выявление нарушений при работе с персональными данными, устранение последствий таких
                                нарушений.
                                Технические меры, принимаемые Компанией, включают в себя: осуществление технической и
                                криптографической защиты персональных данных в порядке, установленном
                                Оперативно-аналитическим центром при Президенте Республики Беларусь, в соответствии с
                                классификацией информационных ресурсов (систем), содержащих персональные данные.</p>
                        </div>
                        <div>
                            <p>Трансграничная передача персональных данных</p>

                            <p>Компания до начала осуществления трансграничной передачи персональных данных обязано
                                убедиться в том, что иностранным государством, на территорию которого предполагается
                                осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов
                                персональных данных.
                                Трансграничная передача персональных данных на территории иностранных государств, не
                                отвечающих вышеуказанному требованию, может осуществляться только в случаях,
                                предусмотренных Законодательством.</p>
                        </div>
                    </div>
                </section>

                <CtaForm
                    isFry={false}
                    className={pageStyles.pageSection}
                    isWhiteBackground
                />
            </DefaultLayout>
        )
    }
    if (localizationContext.locale === 'kz-KZ') {
        return (
            <DefaultLayout
                title="Дербес деректерді өңдеуге қатысты саясат| Quick Resto"
                metaTags={metaTags}
                mainWrapperClassName={classNames(
                    pageStyles.pageWrapper,
                    pageStyles.pageGray,
                    styles.docs
                )}
                linkCanonical="https://quickresto.by/privacy_policy/"
                isHeaderFixed={true}
                footerClassName={pageStyles.pageSection}
            >
                <section
                    className={classNames(styles.docs__container, pageStyles.pageSection)}
                >
                    <div className={styles.docs__content}>
                        <h1>
                            Дербес деректерді өңдеуге қатысты саясат
                        </h1>

                        <div>
                            <p></p>
                            <p>1. Жалпы ережелер</p>
                            <p>Осы дербес деректерді өңдеу саясаты "Дербес деректер туралы" 27.07.2006 №152-ФЗ
                                Федералдық заңының талаптарына сәйкес жасалған және "Квик Ресто" ЖШҚ (бұдан
                                әрі &ndash; Оператор) қабылдайтын дербес деректерді өңдеу тәртібін және дербес
                                деректердің қауіпсіздігін қамтамасыз ету шараларын айқындайды.</p>
                            <p>1.1. Оператор адамның және азаматтың дербес деректерін өңдеу кезінде оның құқықтары мен
                                бостандықтарын сақтауды, оның ішінде жеке өмірге, жеке және отбасылық құпияға қол
                                сұғылмаушылық құқықтарын қорғауды өз қызметін жүзеге асырудың маңызды мақсаты мен шартын
                                қояды.</p>
                            <p>1.2. Оператордың дербес деректерді өңдеуге қатысты осы саясат (бұдан әрі - Саясат)
                                Оператор https://quickresto.ru/ веб-сайтына кірушілер туралы ала алатын бүкіл ақпаратқа
                                қолданылады.</p>
                            <p>2. Саясатта пайдаланылатын негізгі ұғымдар</p>
                            <p>2.1. Дербес деректерді автоматтандырылған өңдеу - есептеу техникасы құралдарының
                                көмегімен дербес деректерді өңдеу;</p>
                            <p>2.2. Дербес деректерді бұғаттау - дербес деректерді өңдеуді уақытша тоқтату (өңдеу дербес
                                деректерді нақтылау үшін қажет болған жағдайларды қоспағанда);</p>
                            <p>2.3. Веб-сайт &ndash; https://quickresto.ru/ желілік мекенжай бойынша интернет желісінде
                                олардың қолжетімділігін қамтамасыз ететін графикалық және ақпараттық материалдардың,
                                сондай-ақ ЭЕМ арналған бағдарламалардың және дерекқорлардың жиынтығы;</p>
                            <p>2.4. Дербес деректердің ақпараттық жүйесі - дерекқорлар құрамында бар дербес деректердің
                                және оларды өңдеуді қамтамасыз ететін ақпараттық технологиялар мен техникалық
                                құралдардың жиынтығы;</p>
                            <p>2.5. Дербес деректерді иесіздендіру - нәтижесінде қосымша ақпаратты пайдаланбай дербес
                                деректердің нақты Пайдаланушыға немесе дербес деректердің өзге субъектісіне тиесілігін
                                анықтау мүмкін болмайтын әрекеттер;</p>
                            <p>2.6. Дербес деректерді өңдеу - дербес деректерді жинауды, жазуды, жүйелеуді, жинақтауды,
                                сақтауды, нақтылауды (жаңартуды, өзгертуді), шығаруды, пайдалануды, беруді (таратуды,
                                ұсынуды, қол жеткізуді), иесіздендіруді, бұғаттауды, жоюды қоса алғанда, автоматтандыру
                                құралдарын пайдалана отырып немесе дербес деректермен осындай құралдарды пайдаланбай
                                жасалатын кез келген әрекет (операция) немесе әрекеттер (операциялар) жиынтығы;</p>
                            <p>2.7. Оператор &ndash; өздігінен немесе басқа тұлғалармен бірлесіп дербес деректерді
                                өңдеуді ұйымдастыратын және (немесе) жүзеге асыратын, сондай-ақ дербес деректерді
                                өңдеудің мақсаттарын, өңдеуге жататын дербес деректердің құрамын, дербес деректермен
                                жасалатын әрекеттерді (операцияларды) айқындайтын мемлекеттік орган, муниципалдық орган,
                                заңды немесе жеке тұлға;</p>
                            <p>2.8. Жеке деректер - https://quickresto.ru/ веб-сайтының белгіленген немесе белгіленетін
                                Пайдаланушысына тікелей немесе жанама қатысты кез келген ақпарат;</p>
                            <p>2.9. Пайдаланушы &ndash; https://quickresto.ru/ веб-сайтының кез келген келушісі.</p>
                            <p>2.10. Дербес деректерді ұсыну - белгілі бір тұлғаға немесе белгілі бір тұлғалар тобына
                                дербес деректерді ашуға бағытталған іс-әрекеттер;</p>
                            <p>2.11. Дербес деректерді тарату - дербес деректерді белгісіз тұлғалар тобына ашуға (дербес
                                деректерді беру) немесе шектелмеген тұлғалар тобының дербес деректерімен танысуға, оның
                                ішінде бұқаралық ақпарат құралдарында дербес деректерді жариялауға,
                                ақпараттық-телекоммуникациялық желілерде орналастыруға немесе дербес деректерге қандай
                                да бір өзге тәсілмен қол жеткізуге бағытталған кез келген іс-әрекеттер;</p>
                            <p>2.12. Дербес деректерді трансшекаралық беру - дербес деректерді шет мемлекеттің аумағына
                                шет мемлекеттің билік органына, шетелдік жеке немесе шетелдік заңды тұлғаға беру;</p>
                            <p>3. Оператор Пайдаланушының келесі дербес деректерін өңдей алады</p>
                            <p>3.1. Тегі, аты, әкесінің аты.</p>
                            <p>3.2. Телефон нөмірі.</p>
                            <p>3.3. Электрондық пошта мекенжайы.</p>
                            <p>3.4. Туған жылы, айы, күні, орны.</p>
                            <p>3.5. Фотосурет.</p>
                            <p>3.6. Әлеуметтік желілердегі профильге сілтеме.</p>
                            <p>3.7. Тапсырыс берілген тауарлар немесе қызметтер тізімі.</p>
                            <p>3.8. Тұратын қаласы және орналасқан жері туралы деректер.</p>
                            <p>3.9. IP-мекенжайы.</p>
                            <p>3.10. Сонымен қатар сайтта интернет-статистика сервистері (Яндекс Метрика, Гугл Аналитика
                                және т.б.) көмегімен келушілер туралы иесіздендірілген деректерді (оның
                                ішінде &laquo;cookie&raquo; файлдарын) жинау және өңдеу жүргізіледі.</p>
                            <p>3.11. Жоғарыда аталған деректер бұдан әрі Саясат мәтіні бойынша Дербес деректер деген
                                жалпы ұғыммен біріктірілген.</p>
                            <p>4. Дербес деректерді өңдеу мақсаттары</p>
                            <p>4.1. Пайдаланушының дербес деректерін өңдеу мақсаты - электрондық хаттарды жіберу арқылы
                                Пайдаланушыны хабардар ету.</p>
                            <p>4.2. Сондай-ақ, Оператор Пайдаланушыға жаңа өнімдер мен қызметтер, арнайы ұсыныстар және
                                әртүрлі оқиғалар туралы хабарламалар жіберуге құқылы. Пайдаланушы әрқашан Операторға
                                support@quickresto.ru электрондық пошта мекенжайына "Жаңа өнімдер мен қызметтер және
                                арнайы ұсыныстар туралы хабарламалардан бас тарту" деген белгісі бар хат жіберу арқылы
                                ақпараттық хабарламаларды алудан бас тарта алады</p>
                            <p>4.3. Интернет-статистика сервистерінің көмегімен жиналатын Пайдаланушылардың
                                иесіздендірілген деректері Пайдаланушылардың сайттағы іс-әрекеттері туралы ақпарат
                                жинауға, сайттың сапасын және оның мазмұнын жақсартуға қызмет етеді.</p>
                            <p>5. Дербес деректерді өңдеудің құқықтық негіздері</p>
                            <p>5.1. Оператор Пайдаланушының дербес деректерін Пайдаланушы https://quickresto.ru/
                                сайтында орналасқан арнайы нысандар арқылы оларды өз бетінше толтырған және/немесе
                                жіберген жағдайда ғана өңдейді. Тиісті нысандарды толтыру және/немесе Операторға өзінің
                                дербес деректерін жіберу арқылы Пайдаланушы осы Саясатқа келісімін білдіреді.</p>
                            <p>5.2. Оператор Пайдаланушы браузерінің теңшелімдерінде рұқсат етілген жағдайда Пайдаланушы
                                туралы иесіздендірілген деректерді өңдейді ("cookie" файлдарын сақтау және JavaScript
                                технологиясын пайдалану кіреді).</p>
                            <p>6. Дербес деректерді жинау, сақтау, беру және өңдеудің басқа да түрлерінің тәртібі</p>
                            <p>Оператор өңдейтін дербес деректердің қауіпсіздігі дербес деректерді қорғау саласындағы
                                қолданыстағы заңнаманың талаптарын толық көлемде орындау үшін қажетті құқықтық,
                                ұйымдастырушылық және техникалық шараларды іске асыру жолымен қамтамасыз етіледі.</p>
                            <p>6.1. Оператор дербес деректердің сақталуын қамтамасыз етеді және уәкілетті емес
                                тұлғалардың дербес деректеріне қол жеткізуін болдырмайтын барлық ықтимал шараларды
                                қабылдайды.</p>
                            <p>6.2. Қолданыстағы заңнаманың орындалуына байланысты жағдайларды қоспағанда,
                                Пайдаланушының дербес деректері ешқашан, еш жағдайда үшінші тұлғаларға берілмейді.</p>
                            <p>6.3. Дербес деректерде дәлсіздіктер анықталған жағдайда Пайдаланушы Операторға
                                Оператордың support@quickresto.ru электрондық пошта мекенжайына "Дербес деректерді
                                өзектендіру" белгісімен хабарлама жіберу арқылы оларды өз бетінше өзектендіре алады.</p>
                            <p>6.4. Дербес деректерді өңдеу мерзімі шектелмеген болып табылады. Пайдаланушы кез келген
                                сәтте Операторға электрондық пошта арқылы Оператордың support@quickresto.ru электрондық
                                мекенжайына "Дербес деректерді өңдеуге келісімді кері қайтарып алу" деген белгісі бар
                                хабарлама жіберу арқылы дербес деректерді өңдеуге өзінің келісімін қайтарып ала
                                алады.</p>
                            <p>7. Дербес деректерді трансшекаралық беру</p>
                            <p>7.1. Оператор дербес деректерді трансшекаралық беруді жүзеге асыру басталғанға дейін
                                аумағына дербес деректерді беруді жүзеге асыру болжанатын шет мемлекеттің дербес
                                деректер субъектілерінің құқықтарын сенімді қорғауды қамтамасыз ететініне көз жеткізуге
                                міндетті.</p>
                            <p>7.2. Жоғарыда көрсетілген талаптарға сәйкес келмейтін шет мемлекеттердің аумағында дербес
                                деректерді трансшекаралық беру дербес деректер субъектісінің оның дербес деректерін
                                трансшекаралық беруге жазбаша нысанда келісімі болған және/немесе дербес деректер
                                субъектісі тарапы болып табылатын шартты орындаған жағдайда ғана жүзеге асырылуы
                                мүмкін.</p>
                            <p>8. Қорытынды ережелер</p>
                            <p>8.1. Пайдаланушы Операторға support@quickresto.ru электрондық поштасы арқылы хабарласу
                                арқылы өзінің дербес деректерін өңдеуге қатысты қызықтыратын мәселелер бойынша кез
                                келген түсініктемелер ала алады.</p>
                            <p>8.2. Бұл құжатта Оператордың дербес деректерді өңдеу саясатындағы кез келген өзгерістер
                                көрсетіледі. Саясат жаңа нұсқамен ауыстырылғанға дейін мерзімсіз жұмыс істейді.</p>
                            <p>8.3. Еркін қол жетімділіктегі Саясаттың өзекті нұсқасы Интернет желісінде
                                https://quickresto.ru/privacy_policy/ мекенжайы бойынша орналасқан.</p>
                        </div>
                    </div>
                </section>

                <CtaForm
                    isFry={false}
                    className={pageStyles.pageSection}
                    isWhiteBackground
                />
            </DefaultLayout>
        )
    }

    return (
        <DefaultLayout
            title="Политика в отношении обработки персональных данных | Quick Resto"
            metaTags={metaTags}
            mainWrapperClassName={classNames(
                pageStyles.pageWrapper,
                pageStyles.pageGray,
                styles.docs
            )}
            linkCanonical="https://quickresto.ru/privacy_policy/"
            isHeaderFixed={true}
            footerClassName={pageStyles.pageSection}
        >
            <section
                className={classNames(styles.docs__container, pageStyles.pageSection)}
            >
                <div className={styles.docs__content}>
                    <h1>
                        Политика в&nbsp;отношении
                        <br/>
                        обработки персональных данных
                    </h1>

                    <div>
                        <p>1. Общие положения</p>

                        <p>
                            Настоящая политика обработки персональных данных составлена в
                            соответствии с&nbsp;требованиями Федерального закона от
                            27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок
                            обработки персональных данных и меры по обеспечению безопасности
                            персональных данных, предпринимаемые ООО «Квик Ресто» (далее –
                            Оператор).
                        </p>

                        <p>
                            1.1. Оператор ставит своей важнейшей целью и условием
                            осуществления своей деятельности соблюдение прав и свобод человека
                            и гражданина при обработке его персональных данных, в том числе
                            защиты прав на неприкосновенность частной жизни, личную и семейную
                            тайну.
                        </p>

                        <p>
                            1.2. Настоящая политика Оператора в отношении обработки
                            персональных данных (далее – Политика) применяется ко всей
                            информации, которую Оператор может получить о посетителях
                            веб-сайта https://quickresto.ru/.
                        </p>
                    </div>

                    <div>
                        <p>2. Основные понятия, используемые в Политике</p>

                        <p>
                            2.1. Автоматизированная обработка персональных данных – обработка
                            персональных данных с помощью средств вычислительной техники;
                        </p>

                        <p>
                            2.2. Блокирование персональных данных – временное прекращение
                            обработки персональных данных (за исключением случаев, если
                            обработка необходима для уточнения персональных данных);
                        </p>

                        <p>
                            2.3. Веб-сайт – совокупность графических и информационных
                            материалов, а также программ для ЭВМ и баз данных, обеспечивающих
                            их доступность в сети интернет по сетевому адресу
                            https://quickresto.ru/;
                        </p>

                        <p>
                            2.4. Информационная система персональных данных — совокупность
                            содержащихся в базах данных персональных данных, и обеспечивающих
                            их обработку информационных технологий и технических средств;
                        </p>

                        <p>
                            2.5. Обезличивание персональных данных — действия, в результате
                            которых невозможно определить без использования дополнительной
                            информации принадлежность персональных данных конкретному
                            Пользователю или иному субъекту персональных данных;
                        </p>

                        <p>
                            2.6. Обработка персональных данных – любое действие (операция) или
                            совокупность действий (операций), совершаемых с использованием
                            средств автоматизации или без использования таких средств с
                            персональными данными, включая сбор, запись, систематизацию,
                            накопление, хранение, уточнение (обновление, изменение),
                            извлечение, использование, передачу (распространение,
                            предоставление, доступ), обезличивание, блокирование, удаление,
                            уничтожение персональных данных;
                        </p>

                        <p>
                            2.7. Оператор – государственный орган, муниципальный орган,
                            юридическое или физическое лицо, самостоятельно или совместно с
                            другими лицами организующие и (или) осуществляющие обработку
                            персональных данных, а также определяющие цели обработки
                            персональных данных, состав персональных данных, подлежащих
                            обработке, действия (операции), совершаемые с персональными
                            данными;
                        </p>

                        <p>
                            2.8. Персональные данные – любая информация, относящаяся прямо или
                            косвенно к определенному или определяемому Пользователю веб-сайта
                            https://quickresto.ru/;
                        </p>

                        <p>
                            2.9. Пользователь – любой посетитель веб-сайта
                            https://quickresto.ru/.
                        </p>

                        <p>
                            2.10. Предоставление персональных данных – действия, направленные
                            на раскрытие персональных данных определенному лицу или
                            определенному кругу лиц;
                        </p>

                        <p>
                            2.11. Распространение персональных данных – любые действия,
                            направленные на раскрытие персональных данных неопределенному
                            кругу лиц (передача персональных данных) или на ознакомление с
                            персональными данными неограниченного круга лиц, в том числе
                            обнародование персональных данных в средствах массовой информации,
                            размещение в информационно-телекоммуникационных сетях или
                            предоставление доступа к персональным данным каким-либо иным
                            способом;
                        </p>

                        <p>
                            2.12. Трансграничная передача персональных данных – передача
                            персональных данных на территорию иностранного государства органу
                            власти иностранного государства, иностранному физическому или
                            иностранному юридическому лицу;
                        </p>

                        <p/>
                    </div>

                    <div>
                        <p>
                            3. Оператор может обрабатывать следующие персональные данные
                            Пользователя
                        </p>

                        <p>3.1. Фамилия, имя, отчество.</p>

                        <p>3.2. Номер телефона.</p>

                        <p>3.3. Адрес электронной почты.</p>

                        <p>3.4. Год, месяц, дата, место рождения.</p>

                        <p>3.5. Фотография.</p>

                        <p>3.6. Ссылка на профиль в соцсетях.</p>

                        <p>3.7. Список заказанных товаров или услуг.</p>

                        <p>3.8. Город проживания и данные о местоположении.</p>

                        <p>3.9. IP-адрес.</p>

                        <p>
                            3.10. Также на сайте происходит сбор и обработка обезличенных
                            данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
                            интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
                        </p>

                        <p>
                            3.11. Вышеперечисленные данные далее по тексту Политики объединены
                            общим понятием Персональные данные.
                        </p>
                    </div>

                    <div>
                        <p>4. Цели обработки персональных данных</p>

                        <p>
                            4.1. Цель обработки персональных данных Пользователя —
                            информирование Пользователя посредством отправки электронных
                            писем.
                        </p>

                        <p>
                            4.2. Также Оператор имеет право направлять Пользователю
                            уведомления о новых продуктах и услугах, специальных предложениях
                            и различных событиях. Пользователь всегда может отказаться от
                            получения информационных сообщений, направив Оператору письмо на
                            адрес электронной почты support@quickresto.ru с пометкой «Отказ от
                            уведомлений о новых продуктах и услугах и специальных
                            предложениях»
                        </p>

                        <p>
                            4.3. Обезличенные данные Пользователей, собираемые с помощью
                            сервисов интернет-статистики, служат для сбора информации о
                            действиях Пользователей на сайте, улучшения качества сайта и его
                            содержания.
                        </p>
                    </div>

                    <div>
                        <p>5. Правовые основания обработки персональных данных</p>

                        <p>
                            5.1. Оператор обрабатывает персональные данные Пользователя только
                            в случае их заполнения и/или отправки Пользователем самостоятельно
                            через специальные формы, расположенные на сайте
                            https://quickresto.ru/. Заполняя соответствующие формы и/или
                            отправляя свои персональные данные Оператору, Пользователь
                            выражает свое согласие с данной Политикой.
                        </p>

                        <p>
                            5.2. Оператор обрабатывает обезличенные данные о Пользователе в
                            случае, если это разрешено в настройках браузера Пользователя
                            (включено сохранение файлов «cookie» и использование технологии
                            JavaScript).
                        </p>
                    </div>

                    <div>
                        <p>
                            6. Порядок сбора, хранения, передачи и других видов обработки
                            персональных данных
                        </p>

                        <p>
                            Безопасность персональных данных, которые обрабатываются
                            Оператором, обеспечивается путем реализации правовых,
                            организационных и технических мер, необходимых для выполнения в
                            полном объеме требований действующего законодательства в области
                            защиты персональных данных.
                        </p>

                        <p>
                            6.1. Оператор обеспечивает сохранность персональных данных и
                            принимает все возможные меры, исключающие доступ к персональным
                            данным неуполномоченных лиц.
                        </p>

                        <p>
                            6.2. Персональные данные Пользователя никогда, ни при каких
                            условиях не будут переданы третьим лицам, за исключением случаев,
                            связанных с исполнением действующего законодательства.
                        </p>

                        <p>
                            6.3. В случае выявления неточностей в персональных данных,
                            Пользователь может актуализировать их самостоятельно, путем
                            направления Оператору уведомление на адрес электронной почты
                            Оператора support@quickresto.ru с пометкой «Актуализация
                            персональных данных».
                        </p>

                        <p>
                            6.4. Срок обработки персональных данных является неограниченным.
                            Пользователь может в любой момент отозвать свое согласие на
                            обработку персональных данных, направив Оператору уведомление
                            посредством электронной почты на электронный адрес Оператора
                            support@quickresto.ru с пометкой «Отзыв согласия на обработку
                            персональных данных».
                        </p>
                    </div>

                    <div>
                        <p>7. Трансграничная передача персональных данных</p>

                        <p>
                            7.1. Оператор до начала осуществления трансграничной передачи
                            персональных данных обязан убедиться в том, что иностранным
                            государством, на территорию которого предполагается осуществлять
                            передачу персональных данных, обеспечивается надежная защита прав
                            субъектов персональных данных.
                        </p>

                        <p>
                            7.2. Трансграничная передача персональных данных на территории
                            иностранных государств, не отвечающих вышеуказанным требованиям,
                            может осуществляться только в случае наличия согласия в письменной
                            форме субъекта персональных данных на трансграничную передачу его
                            персональных данных и/или исполнения договора, стороной которого
                            является субъект персональных данных.
                        </p>
                    </div>

                    <div>
                        <p>8. Заключительные положения</p>

                        <p>
                            8.1. Пользователь может получить любые разъяснения по интересующим
                            вопросам, касающимся обработки его персональных данных,
                            обратившись к Оператору с помощью электронной почты
                            support@quickresto.ru.
                        </p>

                        <p>
                            8.2. В данном документе будут отражены любые изменения политики
                            обработки персональных данных Оператором. Политика действует
                            бессрочно до замены ее новой версией.
                        </p>

                        <p>
                            8.3. Актуальная версия Политики в свободном доступе расположена в
                            сети Интернет по адресу https://quickresto.ru/privacy_policy/.
                        </p>
                    </div>
                </div>
            </section>

            <CtaForm
                isFry={false}
                className={pageStyles.pageSection}
                isWhiteBackground
            />
        </DefaultLayout>
    )
}
